export const APP_URL_BASE = "https://app.contracthound.com/";
export const APP_URL_REGISTER = `${APP_URL_BASE}accounts/register/`;
export const APP_URL_LOGIN = `${APP_URL_BASE}accounts/login/`;
export const WEBSITE_URL_REQUESTDEMO = "/request-demo/"
export const WEBSITE_URL_CONTACT = "/contact/";
export const BASE_PRICE_MONTH = 95;
export const BASE_PRICE_YEAR = 950;
export const COLLECT_EMAIL_API = "https://app.contracthound.com/api/v1/marketing/contacts/";
export const POSTHOG_KEY = "phc_hXGW33dq8b6mPZsWKpdENkUYtyLc50AQy9O7XCYPJX3";

export const CURRENCIES = {
  USD: {
    symbol: '$',
    base_price_month: 95,
    base_price_year: 950,
    flag: '🇺🇸'
  },
  EUR: {
    symbol: '€',
    base_price_month: 90,
    base_price_year: 900,
    flag: '🇪🇺'
  },
  GBP: {
    symbol: '£',
    base_price_month: 75,
    base_price_year: 750,
    flag: '🇦🇺'
  },
};
