import '@/styles/global.css';
import React from 'react';
import { PostHogProvider } from 'posthog-js/react';
import { POSTHOG_KEY } from '@/constants';

export const wrapRootElement = ({ element }) => {
  const isDebugMode = typeof window !== 'undefined' &&
    new URLSearchParams(window.location.search).get('posthog.debug') === 'true';

  const posthog_options = {
    api_host: 'https://app.posthog.com',
    capture_pageleave: true,
    capture_pageview: false,
    debug: isDebugMode,
  };

  return (
    <PostHogProvider
      apiKey={POSTHOG_KEY}
      options={posthog_options}
    >
      {element}
    </PostHogProvider>
  )
}
